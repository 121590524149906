.btn-round {
    border-radius: 50%;
    overflow: hidden;
}

.add-menu {
    z-index: 100;
}

.user-dropdown {
    position: absolute;
    right: 15px;
    z-index: 100;
    display: none;
    height: max-content;
    width: max-content;
    padding: 15px;
    margin-top: 55px;
    background-color: #fff;
    border-radius: 4px;
}

    .user-dropdown.show {
        display: block;
    }

    .user-dropdown button {
        margin-top: 10px;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

.btn-quick-actions {
    background-color: #fff7e8;
    border-color: #fff7e8;
    border-radius: 50%;
    padding: 10px;
}

    .btn-quick-actions:hover {
        background-color: #ffebc6;
        border-color: #ffebc6;
    }

.kt-header-menu {
    margin-left: 21px;
}

.kt-datatable__row {
    padding-left: 13px;
    padding-right: 13px;
}

.user-dropdown h5 {
    font-size: 16px;
}

.user-dropdown p {
    margin-bottom: unset;
}

.logout-icon {
    height: 24px;
}

.user-link {
    color: #1a73e9;
    margin-left: 14px;
    font-weight: 400;
}

    .user-link:hover {
        color: #0962d8;
    }

.padding-hor-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.padding-hor-4 {
    padding-left: 4px;
    padding-right: 4px;
}
