@media (min-width: 1025px) {
    .kt-header {
        background-color: #ffffff;
        border-bottom: 1px solid #eff0f6;
    }

    .kt-header--fixed:not(.kt-subheader--fixed) .kt-header {
        border-bottom: 0;
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    }

    .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
        border-radius: 4px;
    }

        .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
            color: #6c7293;
            font-weight: 500;
        }

        .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow {
            color: #c8cde8;
        }

        .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-icon {
            color: #c8cde8;
        }

    .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link {
        background-color: rgba(77, 89, 149, 0.06);
    }

        .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__link-text {
            color: #5d78ff;
        }

        .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__hor-arrow, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__hor-arrow, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__hor-arrow {
            color: #5d78ff;
        }

        .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__hor-icon, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__hor-icon, .kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link .kt-menu__hor-icon {
            color: #5d78ff;
        }
}

.kt-aside-secondary--enabled .kt-header .kt-header__topbar {
    padding-right: 0;
}

.kt-header .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i {
    transition: all 0.3s;
    color: #c8cad5;
}

.kt-header .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon svg g [fill] {
    fill: #5d78ff;
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-welcome {
    transition: all 0.3s;
    color: #959cb6;
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-username {
    transition: all 0.3s;
    color: #6c7293;
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-badge.kt-badge--username {
    height: 35px;
    width: 35px;
    font-size: 1.4rem;
    font-weight: 600;
}

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--quick-panel .kt-header__topbar-icon i {
    color: #c8cad5;
}

.kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon, .kt-header .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon {
    transition: all 0.3s;
    background-color: rgba(77, 89, 149, 0.06);
}

    .kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon i, .kt-header .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon i {
        transition: all 0.3s;
        color: #5d78ff;
    }

.kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--user .kt-header__topbar-user, .kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--user .kt-header__topbar-user {
    transition: all 0.3s;
    background-color: rgba(77, 89, 149, 0.06);
}

.kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel, .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel {
    transition: all 0.3s;
    background-color: #5d78ff;
}

    .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel .kt-header__topbar-icon, .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel .kt-header__topbar-icon {
        transition: all 0.3s;
        background-color: transparent !important;
    }

        .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--quick-panel .kt-header__topbar-icon i, .kt-aside-secondary--enabled .kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--quick-panel .kt-header__topbar-icon i {
            transition: all 0.3s;
            color: #ffffff;
        }

.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--search .kt-input-icon .kt-input-icon__icon i {
    color: #74788d;
}

@media (max-width: 1024px) {
    .kt-header-mobile {
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    }

    .kt-header__topbar {
        background-color: #ffffff;
        box-shadow: none;
    }

    .kt-header__topbar--mobile-on .kt-header-mobile {
        box-shadow: none;
        border-bottom-color: transparent;
    }

    .kt-header__topbar--mobile-on .kt-header__topbar {
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
        border-top: 1px solid #ebedf2;
    }
}
